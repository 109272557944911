////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/lobby/space/module/OverlapInfo"
import GuideBalloon from "components/common/form/GuideBalloon"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 컴포넌트 연결
import TemplateObject from 'components/store/template/TemplateObject';
import PreviewDisplay from 'components/display/PreviewDisplay' // 프리뷰 디스플레이
import StaticDisplay from 'components/display/StaticDisplay'; // 사이즈가 지정된 정적 디스플레이
// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as templateAPI from 'service/api/template' ; 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/**
 * 
 * @param {*} target 
 * @param {*} parent : 해당 부모 컨테이너
 * @returns 
 */
function TemplateStoreDetail({target,parent,clickDownload,downloadPage,clickListButton}) {
  
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [TargetTemplate, setTargetTemplate] = useState(null);
    const [SelectPage, setSelectPage] = useState(null);
    // rel 

    useEffect(() => {
        getTemplate()
    }, []);



    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const getTemplate =  async () => {
        const Response = await templateAPI.getTemplateConcatForUUID(target);
        if(Response){
            setTargetTemplate(Response)
        }
        setSelectPage(Response?.content?.order?.[0])
    }

    const List = TargetTemplate?.content?.page.map((page,index)=>{return(
        <div className = {page.uuid == SelectPage? 'Pages SelectPage':'Pages' } key={page.uuid} onClick={()=>setSelectPage(page.uuid)} >
        <div className = "PagesDisplay">
          <StaticDisplay page={page} previewWidth={192} previewHeight={108} referenceWidth={TargetTemplate.meta.resolution.width} referenceHeight={TargetTemplate.meta.resolution.height} option={{align:'center'}} />
        </div>
        <div className = "PagesInfo">
            <p className = "PagesTitle" >
                <span className='PageTitleData'>
                {page.page_title?page.page_title:`페이지 ${index+1}`}
                </span> 
                {parent=='editor'?
                <GuideBalloon guideText={<p> 해당 페이지를 가져옵니다 </p>} direction='top'>
                    <span className='PageDownload' onClick={()=>{downloadPage?.(page)}}>
                        {Editor.icon({icon:'system_update_alt',size:20,lineHeight:40})}
                    </span> 
                </GuideBalloon>
            :null}
                </p>
            {page.page_summary?
              <p className = "PagesSummary" >{page.page_summary}</p>
            :
              <p className = "PagesSummary nullData" >이 페이지는 설명이 없습니다.</p>
            }
        </div>
  
      </div>
     )})

    return (
        <div id='TemplateStoreDetail' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        {/* <nav  id='TemplateStoreDetailNav' >
            <div className='categoryList'>
                    <button className='category' onClick={()=>clickListButton()}>
        
                        &nbsp;
                        목록으로
                    </button>
            </div>

        </nav> */}
        <div id='TemplateStoreDetailMain' >
        <div className='backButton' onClick={()=>clickListButton()}>
        {Editor.icon({icon:'arrow_back',size:20,lineHeight:30})}  뒤로가기
        </div>
        <div id='DetailHeader'>
            <div className='HeaderLeft'>
    
            </div>
            <div className='HeaderCenter'>
    
                <div className='TemplatePreview'>
                    <PreviewDisplay pageList={TargetTemplate?.content?.page??[]} pageUuid={SelectPage} referenceWidth={TargetTemplate?.meta?.resolution?.width??0} referenceHeight={TargetTemplate?.meta?.resolution?.height??0}/>         
                </div>

            </div>
        
            <div className='TemplateInfo'>
                <div className='TemplateTitle'>
                    {TargetTemplate?.title}
                </div>
                <div className='TemplateSummary'>
                    {TargetTemplate?.summary}
                </div>
                <div className='TemplateOptions'>
                    {parent=='lobby'?
                    <button className='useTemplate' onClick={()=>clickDownload('overlap',target)}>
                        {Editor.icon({icon:'magnify_fullscreen',size:30,lineHeight:50})}
                        &nbsp; 
                        프로젝트 만들기 
                    </button>
                    :null}
                    {/* {parent=='editor'?
                    <button className='download'>
                        {Editor.icon({icon:'download',size:30,lineHeight:50})}
                        &nbsp; 
                        다운로드
                    </button>
                    :null} */}
                </div>
            </div>

        </div>

        <div id='DetailBody' >
        {List}
        </div>

        </div>

    </div>
    );
  }

  export default TemplateStoreDetail;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    #TemplateStoreDetail {
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        flex-direction: column;
    }
    #TemplateStoreDetail #TemplateStoreDetailNav{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
        animation: TemplateStoreDetailNavkeyframe 1s ease-in-out forwards;

    }

    @keyframes TemplateStoreDetailNavkeyframe {
        from { top: 0px; }
        to { top: 330px; }
    }


    #TemplateStoreDetail #TemplateStoreDetailNav .upload {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
    }
    #TemplateStoreDetail #TemplateStoreDetailNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
    }
    #TemplateStoreDetailNav .categoryList{
        display: flex;
    }


    #TemplateStoreDetailMain{
        display: flex;
        width: 100%; 
        height: 100%;
        flex-direction: column;
        position: relative; 
    }
    #TemplateStoreDetailMain .backButton{
        position: absolute;
        top :20px;
        left : 20px;
        background: #444;
        width: 100px;
        height: 38px;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius : 5px;
    }

    #DetailHeader{
        width: 100%;
        height: 380px;
        background: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        animation: DetailHeader 1s ease-in-out forwards;
        flex-wrap: wrap;
        overflow :auto;

    }
    #DetailHeader .TemplatePreview{
        width: 600px;
        height: 280px;
    }
   
    #DetailHeader .TemplateInfo{
        height: 280px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;

    }

    #DetailHeader .TemplateTitle{
        background: #555;
        height: 30px;
        width: 300px;
        padding : 10px;
        font-size : 30px;
        line-height : 30px;
        border-radius : 10px;
    }
    #DetailHeader .TemplateSummary{
        margin-top : 20px;
        background: #555;
        height: 120px;
        width: 300px;
        padding : 10px;
        font-size : 15px;
        border-radius : 10px;
    }
    #DetailHeader .TemplateOptions{
        margin-top: 20px;
        width: 320px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
    }

    #DetailHeader .TemplateOptions .useTemplate{
        background: orange;
        width: 200px;
        height: 50px;
        border-radius : 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color : #fff;
        font-size : 20px;
    }

    #DetailHeader .TemplateOptions .download{
        background: orange;
        width: 150px;
        height: 50px;
        border-radius : 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color : #fff;
        font-size : 20px;
    }
    @keyframes DetailHeader {
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @media only screen and (max-width: 1400px) {
        #DetailHeader .TemplatePreview{
            width: 300px;
            height: 140px;
        }
        #DetailHeader .TemplateInfo{
            height: 280px;
            display: flex;
            justify-content: start;
            align-items: center;
            flex-direction: column;
    
        }
        #DetailHeader .TemplateTitle{
            background: #555;
            height: 30px;
            width: 200px;
            padding : 10px;
            font-size : 20px;
            line-height : 30px;
            border-radius : 10px;
        }
        #DetailHeader .TemplateSummary{
            margin-top : 20px;
            background: #555;
            height: 120px;
            width: 200px;
            padding : 10px;
            font-size : 12px;
            border-radius : 10px;
        }
        #DetailHeader .TemplateOptions{
            margin-top: 20px;
            width: 200px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;
        }
    
      }

    #TemplateStoreDetail #DetailBody{
        width: calc(100% - 8px);
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        grid-auto-rows: 250px;
        overflow-y: hidden;
        justify-content: center;  /* 가로 방향 가운데 정렬 */
        align-items: center;   
        flex:1;
        padding-right: 8px; /* 스크롤바의 너비 */
    }
    #TemplateStoreDetail #DetailBody:hover {
        width: calc(100%);
        padding-right: 0px; /* 스크롤바의 너비 */
        overflow-y: scroll; /* 마우스 오버 시 스크롤바를 표시합니다. */
      }
    
      /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
      #TemplateStoreDetail #DetailBody:hover::-webkit-scrollbar {
          width: 8px; /* 스크롤바의 너비 */
      }
    
      #TemplateStoreDetail #DetailBody::-webkit-scrollbar-thumb {
          background-color: #000000aa; /* 스크롤바의 색상 */
          border-radius: 4px; /* 스크롤바의 모서리 */
      }
    
      #TemplateStoreDetail #DetailBody::-webkit-scrollbar-track {
          background: transparent; /* 스크롤 트랙의 색상 */
          width: 8px; /* 스크롤바의 너비 */
      }
    
    #TemplateStoreDetail #DetailBody .Pages{
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        width: 210px;
        margin : 0px 35px;
        padding : 10px 0px;
        border-radius : 5px;
    }

    #TemplateStoreDetail #DetailBody .Pages.SelectPage{
        background-color: #444;
    }
    #TemplateStoreDetail #DetailBody .PagesNav{
        display: flex;
        width: 210px;
        height: 60px;
    }
    #TemplateStoreDetail #DetailBody .getTemplateButton{
        background: orange;
        height: 40px;
        width: 40px;
        margin : 10px;
        border-radius : 10px;
    }
    #TemplateStoreDetail #DetailBody .PagesDisplay{
        width: 230px;
        height: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    #TemplateStoreDetail #DetailBody .PagesInfo{

    }
    #TemplateStoreDetail #DetailBody .PagesInfo .PagesTitle{
        width: 192px;
        height: 40px;
        line-height : 40px;
        font-size: 15px;
        font-family : 프리텐다드Regular;
        display:flex;
        justify-content: space-between;
        align-items: center;
        
    }
    #TemplateStoreDetail #DetailBody .PagesInfo .PageTitleData{
        flex : 1;
        overflow: hidden;
        height: 40px;
    }
    #TemplateStoreDetail #DetailBody .PagesInfo .PageDownload{
        width: 20px;
        height: 40px;
        filter : brightness(0.5);
    }
    #TemplateStoreDetail #DetailBody .PagesInfo .PageDownload:hover{
      
        filter : brightness(1);
    }
    
    #TemplateStoreDetail #DetailBody .PagesInfo .PagesSummary{
        width: 192px;
        height: 60px;
        margin: auto;
        padding: 5px 0px ;
        border-top : 1px solid grey;
        font-family : 프리텐다드Regular;
        font-size: 15px;

    }
  
    #TemplateStoreDetail #DetailBody .PagesInfo .PagesSummary.nullData{
       color : grey;
    }
  
   
`;