////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
// 라이브러리 

// [리덕스]스토어 연결
import store from "store";
// [프로바이더]


// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 이미지 연결
import textIcon from 'assets/widget/text.png';
import imageIcon from 'assets/widget/image.png';
import videoIcon from 'assets/widget/video.png';
import timerIcon from 'assets/widget/timer.png';
import particlesIcon from 'assets/widget/Particles.png';
import backgroundIcon from 'assets/widget/Background.png';
import CameraIcon from 'assets/widget/camera.png';
import lineIcon from 'assets/widget/line.png';
import triangleIcon from 'assets/widget/triangle.png';
import rightTriangleIcon from 'assets/widget/rightTriangle.png';
import quadrangleIcon from 'assets/widget/quadrangle.png';
import hexagonIcon from 'assets/widget/hexagon.png';
import circleIcon from 'assets/widget/circle.png';
import noticeIcon from 'assets/widget/notice.png';
import ADalert from 'assets/widget/adalert.png';
import BroadcastAlertIcon from 'assets/widget/BroadcastAlertIcon.png';
import scrollCaptionIcon from 'assets/widget/scrollCaption.png';
import newsCaption from 'assets/widget/NewsCaption.png';
import liveIconIcon from 'assets/widget/liveIconIcon.png';
import ddayIcon from 'assets/widget/dday.png';

// 컴포넌트 연결
import AppendWidget from 'components/edit/editPanel/append/AppendWidget';//오버레이

// 서비스 연결

import * as widgetModel from 'service/model/widgetModel'
import * as EeCoder from 'service/engine/EnCoder'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendWidgetArea({context,panelHeight,panelWidth,category,listOption={}}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    // state
    const [WidgetButtonsList, setWidgetButtonsList] = useState([]);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        setWidgetList()
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);


      useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        setWidgetList()
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, [panelHeight,category]);


    /** 위젯을 생성합니다.
    * @param {string} widgetName 위젯 데이터
    * @param {string} preset 위젯의 기본 모양
    * @param {object} option 위젯의 옵션
    * TODO 더 다양한 값들이 초기값으로 설정 될 수 있도록 해야함
    */
    const CreateWidget = (widgetName,x=null,y=null,preset=null,version=null,option=null) =>{

      const Resolution = context?.overlap?.meta?.resolution
      let reference =  widgetModel?.getReference()?.[widgetName]

      if(!reference){
        reference =  widgetModel?.getReference().Unknown
      }

      let targetData ;


      if(!version){
        targetData = reference.version[reference.default]
      }else{
        targetData = reference?.version?.[version]?? reference.version[reference.default]
      }

      console.log('version',reference?.version[version]);
      const TargetSize  = targetData?.reference?.size
      if(TargetSize){
      x = x!=null? x: TargetSize? Resolution?.width/2-TargetSize?.width/2 :20;
      y = y!=null? y: TargetSize? Resolution?.height/2-TargetSize?.height/2 :20;
      }

 
      const Page = context.nowPage();
      if (!Page?.page_widget){console.error('[에러] : 선택된 페이지가 없어 위젯을 추가 할 수 없습니다. '); return;}
      const NewWidget = EeCoder.add(widgetName,x.toString(),y.toString(),{preset,option},version??reference.default)

      const NewPage ={'page_widget':Page.page_widget.concat(NewWidget)}     
      context.modifyPage({'page_widget':Page.page_widget.concat(NewWidget)},'widgetAdd', {callBack:()=>context.modifySelectWidget(NewWidget.uuid,'uuid')})
    }


    const DropAppendWidget = (target = null,data) => {
      console.log('widgetcreate',target);
      if(target){
        context.dropControl('addWidget',target,data);
      }else{
        context.dropControl(null);
      }
    }

    const setWidgetList = (widget) =>{
      console.log('panelHeight',panelHeight);
      if(!panelHeight){
        return
      }
      const size = (panelHeight-30)/2;

      

      if(category=='all'){
        const ButtonList = WidgetDefine.map( widget  =>  
          <AppendWidget size={size} icon={widget.icon} image={widget.image} title={widget.title} version={widget.version} widget={widget.widget} option={widget.option} create={CreateWidget} dropAppendWidget={DropAppendWidget}/> 
        )
        setWidgetButtonsList(ButtonList)
      }else{
        const TargetList =  WidgetDefine.filter(define => define.category.includes(category) )
      
        const ButtonList = TargetList.map( widget  =>  
          <AppendWidget size={size} icon={widget.icon} image={widget.image} title={widget.title} version={widget.version} widget={widget.widget} option={widget.option} create={CreateWidget} dropAppendWidget={DropAppendWidget}/> 
        )
        setWidgetButtonsList(ButtonList)
      }
     
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section  id = 'AppendWidgetArea'>
                {WidgetButtonsList}
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendWidgetArea);
  


  const EditPageStyle = `

  #AppendWidgetArea {
    display: flex;
    width: calc(100% - 8px);
    flex-wrap: wrap;
    overflow: auto;
    align-items: start;
    justify-content: start;
    padding-right: 8px; /* 스크롤바의 너비 */
    overflow-y: hidden;
  }

  #AppendWidgetArea:hover {
    width: calc(100%);
    padding-right: 0px; /* 스크롤바의 너비 */
    overflow-y: scroll; /* 마우스 오버 시 스크롤바를 표시합니다. */
  }

  /* 크로스 브라우저 호환성을 위한 스크롤바 스타일링 */
  #AppendWidgetArea:hover::-webkit-scrollbar {
      width: 8px; /* 스크롤바의 너비 */
  }

  #AppendWidgetArea::-webkit-scrollbar-thumb {
      background-color: #000000aa; /* 스크롤바의 색상 */
      border-radius: 4px; /* 스크롤바의 모서리 */
  }

  #AppendWidgetArea::-webkit-scrollbar-track {
      background: transparent; /* 스크롤 트랙의 색상 */
      width: 8px; /* 스크롤바의 너비 */
  }



  `;


  // 위젯 목록
  const WidgetDefine = [
    {title : '레거시텍스트', image : textIcon, icon:'title', widget : 'Text', option : {}, version : 1, reference: {}, category : ['basic','text'], tag : []},
    // {title : '테스트텍스트', image : null, icon:'build_circle', widget : 'Text', option : {}, version : 0, reference: {}, category : ['basic','text'], tag : []},
    // {title : '텍스트', image : null, icon:'title', widget : 'Text', option : {}, version : 2, reference: {}, category : ['basic','text'], tag : []},
    {title : '이미지', image : null, icon:'image', widget : 'Image', option : {}, reference: {}, category : ['basic','file'], tag : []},
    {title : '동영상', image : null, icon:'smart_display', widget : 'Video', option : {}, reference: {}, category : ['basic','file'], tag : []},
    {title : '채팅', image : null, icon:'sms', widget : 'Chat', option : {}, reference: {}, category : ['basic','utility','live'], tag : []},
    {title : '메모', image : null, icon:'note_stack', widget : 'Memo', option : {}, reference: {}, category : ['basic','text'], tag : []},
    {title : '타이머', image : null, icon:'timer', widget : 'Timer', option : {}, reference: {}, category : ['basic','utility'], tag : []},
    // {title : '시계', image : null, icon:'nest_clock_farsight_digital', widget : 'Clock', option : {}, reference: {}, category : ['basic','utility'], tag : []},
    {title : '삼각형', image : null, icon:'change_history', widget : 'Shape', option : {preset:'triangle'}, reference: {}, category : ['basic','shape'], tag : []},
    {title : '직각삼각형', image : null, icon:'signal_cellular_4_bar', widget : 'Shape', option : {preset:'rightTriangle'}, reference: {}, category : ['basic','shape'], tag : []},
    {title : '사각형', image : null, icon:'square', widget : 'Shape', option : {preset:'quadrangle'}, reference: {}, category : ['basic','shape'], tag : []},
    // {title : '오각형', image : null, icon:'pentagon', widget : 'Shape', option : {preset:'pentagon'}, reference: {}, category : ['basic','shape'], tag : []},
    {title : '육각형', image : null, icon:'hexagon', widget : 'Shape', option : {preset:'hexagon'}, reference: {}, category : ['basic','shape'], tag : []},
    {title : '원', image : null, icon:'circle', widget : 'Shape', option : {preset:'circle'}, reference: {}, category : ['basic','shape'], tag : []},
    // {title : '별', image : null, icon:'star', widget : 'Shape', option : {preset:'star'}, reference: {}, category : ['basic','shape'], tag : []},

    {title : '스코어보드', image : null, icon:'scoreboard', widget : 'Scoreboard', option : {positionX:0,positionY:10}, reference: {}, category : ['basic','utility','game'], tag : []},

    {title : '밴픽', image : null, icon:'how_to_reg', widget : 'BanPick', option : {positionX:0,positionY:730}, reference: {}, category : ['basic','utility','game'], tag : []},
    // {title : '가져오기', image : null, icon:'input', widget : 'Import', option : {}, reference: {}, category : ['basic','shape'], tag : []},
    // {title : 'PDF', image : null, icon:'picture_as_pdf', widget : 'PDFwidget', option : {}, reference: {}, category : ['basic','file'], tag : []},
    {title : '스크롤 자막', image : null, icon:'text_rotation_none', widget : 'ScrollCaption', option : {positionX:0,positionY:1010}, reference: {}, category : ['basic','text'], tag : []},
    // {title : '파티클', image : null, icon:'snowing', widget : 'Particles', option : {}, reference: {}, category : ['basic','shape'], tag : []},
    {title : '배경화면', image : null, icon:'background_grid_small', widget : 'Background', option : {}, reference: {}, category : ['basic','shape','effect'], tag : []},
    
    // {title : '사각형', image : null, icon:'nest_clock_farsight_digital', widget : 'Shape', option : {}, category : ['basic','shape'], tag : []},
  ] 

  // <WidgetBox viewMode={this.props.viewMode} name="스코어보드" x='0' y='0' type="Scoreboard" info ={null} img={teamPoint}/>
  // <WidgetBox viewMode={this.props.viewMode} name="사각형" type="Shape" preset={'quadrangle'} info ={null} img={quadrangleIcon}/>
  // <WidgetBox viewMode={this.props.viewMode} name="삼각형" type="Shape" preset={'triangle'} info ={null} img={triangleIcon}/>
  // <WidgetBox viewMode={this.props.viewMode} name="직각삼각형" type="Shape" preset={'rightTriangle'} info ={null} img={rightTriangleIcon}/>
  // <WidgetBox viewMode={this.props.viewMode} name="육각형" type="Shape" preset={'hexagon'} info ={null} img={hexagonIcon}/>
  // <WidgetBox viewMode={this.props.viewMode} name="원" type="Shape" preset={'circle'} info ={null} img={circleIcon}/>

