////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import TemplateStoreContainer from "components/store/template/TemplateStoreContainer"
import OverlapCreateByTemplate from "components/lobby/space/module/OverlapCreateByTemplate"
import StoreUpload from "components/store/StoreUpload"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import LG_pre1 from 'assets/guide/LG_pre1.png';
import LG_pre2 from 'assets/guide/LG_pre2.png';
import LG_pre3 from 'assets/guide/LG_pre3.png';
import LG_pre4 from 'assets/guide/LG_pre4.png';
import LG_pre5 from 'assets/guide/LG_pre5.png';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as overlap from 'service/api/overlap';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

function GuideModuleHome({lobbyRoutes,moveBack}) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set
    const history = useHistory();

    // state
    const [Mode, setMode] = useState('list');
    const [Select, setSelect] = useState(null);
    // ref
    // const { width, height, ref } = useResizeDetector();
    // const displayRef = useRef(null);
    
    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    return (
        <div className='GuideModuleHome' >
        <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
        <nav id='GuideModuleHomeNav'>
        <div className='categoryList'>
            {moveBack?
            <button className='category' onClick={()=>(history.goBack())}>
                {Editor.icon({icon:'arrow_back',size:20,lineHeight:30})}
                &nbsp;
                뒤로가기
            </button>:null
            }
            <button className='category'>
                {Editor.icon({icon:'apps',size:20,lineHeight:30})}
                &nbsp;
                모든가이드
            </button>
 
            {/* <button className='category'>
                {Editor.icon({icon:'weight',size:20,lineHeight:30})}
                &nbsp;
                커머스
            </button>

            <button className='category'>
                {Editor.icon({icon:'stadia_controller',size:20,lineHeight:30})}
                &nbsp;
                게임
            </button>

            <button className='category'>
                {Editor.icon({icon:'pie_chart',size:20,lineHeight:30})}
                &nbsp;
                발표
            </button>

            <button className='category'>
                {Editor.icon({icon:'mic',size:20,lineHeight:30})}
                &nbsp;
                스트리밍
            </button> */}
            </div>
        </nav>
        <article id='GuideContent'>

            <section className='GuidePreview' onClick={()=>lobbyRoutes({target:'Guide',option:'startGuide'})}>
                <div className='GuidePreviewImage'>
                    <img src={LG_pre2}/>
                </div>
                <div  className='GuidePreviewInfo'>
                    <div className='guideCategory' style={{backgroundColor:'orange'}}>오버랩</div>
                </div>
                <div className='GuidePreviewText'>
                    <p>오버랩 시작가이드</p>
                </div>
            </section>

            <section className='GuidePreview' onClick={()=>lobbyRoutes({target:'Guide',option:'linkOBS'})}>
                <div className='GuidePreviewImage'>
                    <img src={LG_pre1}/>
                </div>
                <div  className='GuidePreviewInfo'>
                    <div className='guideCategory' style={{backgroundColor:'red'}}>방송</div>
                </div>
                <div className='GuidePreviewText'>
                    <p>OBS와 오버랩 연결하기</p>
                </div>
            </section>

            <section className='GuidePreview' onClick={()=>lobbyRoutes({target:'Guide',option:'editorBasic'})}>
                <div className='GuidePreviewImage'>
                    <img src={LG_pre3}/>
                </div>
                <div  className='GuidePreviewInfo'>
                    <div className='guideCategory' style={{backgroundColor:'blue'}}>편집기</div>
                </div>
                <div className='GuidePreviewText'>
                    <p>편집기 둘러보기</p>
                </div>
            </section>

            <section className='GuidePreview' onClick={()=>lobbyRoutes({target:'Guide',option:'pageBasic'})}>
                <div className='GuidePreviewImage'>
                    <img src={LG_pre4}/>
                </div>
                <div  className='GuidePreviewInfo'>
                    <div className='guideCategory' style={{backgroundColor:'blue'}}>편집기</div>
                </div>
                <div className='GuidePreviewText'>
                    <p>페이지 편집 가이드</p>
                </div>
            </section>


            <section className='GuidePreview' onClick={()=>lobbyRoutes({target:'Guide',option:'widgetBasic'})}>
                <div className='GuidePreviewImage'>
                    <img src={LG_pre5}/>
                </div>
                <div  className='GuidePreviewInfo'>
                    <div className='guideCategory' style={{backgroundColor:'blue'}}>편집기</div>
                </div>
                <div className='GuidePreviewText'>
                    <p>위젯 편집 가이드</p>
                </div>
            </section>
        
        </article>
      
    </div>
    );
  }

  export default GuideModuleHome;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    .GuideModuleHome {

        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        
    }

    #GuideModuleHomeNav{
        top : 0;
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
   
    #GuideModuleHomeNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
    }
    #GuideModuleHomeNav .categoryList{
        display: flex;
    }
    #GuideContent{
        margin-top : 100px;
        width: calc(100% - 40px);
        padding : 0px 20px; 
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        grid-auto-rows: 220px;
    }
   
    #GuideContent .GuidePreview{
        width: 300px;
        height: 200px;
        border-radius : 5px;
        overflow:hidden;
        background-color : #444;
        margin : 10px 20px 10px 0px;
        position: relative; 
    }
    #GuideContent .GuidePreview .GuidePreviewImage img{
        width: 300px;
        height: 150px;
        object-fit:cover;
        animation: fadeOutBackground 1s;
        animation-iteration-count: 1; /* 한 번 실행 */
    }
    #GuideContent .GuidePreview .GuidePreviewInfo {
        width: 300px;
        height: 50px;
        position: absolute;
        top : 0px;
        display : flex;
    }
    #GuideContent .GuidePreview .guideCategory{
        display : flex;
        margin : 5px;
        padding : 5px;
        height: 20px;
        border-radius : 5px;
        box-shadow : 2px 2px 5px #1c1c1c55;
    }
   
    #GuideContent .GuidePreview .GuidePreviewText{
        font-size : 20px;
        height: 40px;
        padding : 5px;
        display: flex;
        font-family : 프리텐다드SemiBold;
        background-color : #444;
    }
    #GuideContent .GuidePreview .GuidePreviewText p{
        font-size : 15px;
        height: 20px;
    }

      @keyframes fadeOutBackground {
        0% {
          background-color: #666; /* 시작 색상 */
        }
        100% {
          background-color: #444; /* 종료 색상 */
        }
      }
      
`;