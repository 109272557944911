////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import logoW from 'assets/logoW.svg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import VarTeam from 'components/edit/editVariable/varTeam/VarTeam';
import VarTeamPlayer from 'components/edit/editVariable/varTeam/VarTeamPlayer';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendFile({fileData,select,dropAppendFile}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [FileList, setFileList] = useState(false);

    // state
    const [SelectTeam, setSelectTeam] = useState(null);

    // rel 



    const onDragStart = (e) => {
      console.log('드레그',fileData)
 
      dropAppendFile(fileData)
      e.dataTransfer.setData('text/plain', 'This is a draggable item');
    };

    const onDragEnd = (e) => {
      // console.log(e,'드레그끝')
      dropAppendFile(null)
    };


   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.

        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);
      console.log('fileData',fileData)
      
    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style> 
                <div className='Files' draggable={dropAppendFile?true:false}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onClick={()=>select(fileData)}>
                    <div  className='FilePreview'>
                      <img className='PreviewImage' src={fileData?.file_preview}/>
                    </div>
                    <div className='FileInfo'>
                      <div className='FileButton'>
                      {/* {Editor.icon({icon:'fullscreen',size:25,lineHeight:30})} */}
                      </div>
                      <div className='FileName'>
                      {fileData?.file_name}
                      </div>
                    </div>
                    <div className='FileOption'>
                      
                    </div>

                </div>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendFile);
  


  const EditPageStyle = `

  `;
  