////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 
import { useGoogleLogin } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google'// 구글 로그인
// 설정 파일
import * as config from 'config/OverlapConfig'

import { withRouter } from 'react-router-dom';

import * as apiAccount from 'service/api/apiAccount'

// [컴포넌트] 위젯 
import MiniView from "components/viewer/MiniView"
import OverlapInfo from "components/lobby/space/module/OverlapInfo"
import GuideBalloon from "components/common/form/GuideBalloon"
// [리덕스]스토어 연결
import store from "store";

// SASS&CSS 연결
import 'sass/login.scss'

// 컴포넌트 연결
import TemplateObject from 'components/store/template/TemplateObject';

import TemplateStoreList from 'components/store/template/TemplateStoreList'
import TemplateStoreDetail from 'components/store/template/TemplateStoreDetail'

// 이미지 연결
import logoW from 'assets/logoW.svg';
import youtube from 'assets/main/lobby/social/youtube.png';
import twitch from 'assets/main/lobby/social/twitch.png';
import afreeca from 'assets/main/lobby/social/afreeca.png';
import facebook from 'assets/main/lobby/social/facebook.png';
import naver from 'assets/main/lobby/social/naver.png';


// 서비스 연결
import * as templateAPI from 'service/api/template' ; 
import * as Editor from 'service/edit/EditService'; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/**
 * @param {*} setMode 
 * @param {*} parent 
 * @returns 
 */
function TemplateStoreContainer({setMode,backEditor,downloadPage,setSelect,parent}) {
  
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    const [Target, setTarget] = useState(null); // 타겟이 없으면
    const [Content, setContent] = useState('List'); // 보여주는 컨텐츠
    // rel 

    useEffect(() => {
        getTemplate()
    }, []);



    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////

    const getTemplate =  async () => {
        
        // const Response = await templateAPI.getList();
        // // setTemplateList(Response) 
    }

    const mode = () => {

    }

    /**
     * 탬플릿을 선택 했을때 해당 템플릿의 상세 페이지로 이동하기위해 State 를 수정합니다. 
     * @param {string} uuid 상세 페이지를 보여줄 아이디
     */
    const clickTemplate = (uuid) => {
        setTarget(uuid)
        setContent('Detail')
    }

    const clickListButton = () => {
        setTarget(null)
        setContent('List')
    }


    const clickDownload = (type,uuid) => {

        switch (type) {
            case 'overlap':
                setSelect({type:'overlap',uuid})
                setMode('download');
                break;
            case 'page':

                setMode('download');
                break;
        
            default:
                break;
        }

    }

    const clickUpload = () => {
        if(parent=='lobby'){
            setMode('upload');
        }else{
            console.error(`editor : \n ⛔️ 로비페이지가 아닌  ${parent}페이지 에서는 업로드 기능을 사용 할 수 없습니다.`);
        }
    }

    const contents = () => {

        switch (Content) {
            case 'List':
                return (<TemplateStoreList parent={parent} clickUpload={clickUpload} backEditor={backEditor} clickTemplate={(uuid)=>clickTemplate(uuid)}/>)
            case 'Detail':
                return (<TemplateStoreDetail target={Target} clickListButton={clickListButton} clickDownload={clickDownload} downloadPage={downloadPage} parent={parent} />)
        
            default:
                return null
        }
    
    }


 
    return (
        <div id='TemplateStoreContainer' >
            <style jsx>{`${SpaceModuleStyle}${ContainerStyle}`}</style>
            {contents()}
            
        </div>
    );
  }

  export default TemplateStoreContainer;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const SpaceModuleStyle = `


`;

const ContainerStyle = `
    #TemplateStoreContainer {
        display: flex;
        width: 100%; 
        height: 100%;
        position: relative; 
        flex-direction: column;
    }
    #TemplateStoreContainerNav{
        height: 50px;
        width: calc(100% - 40px);
        padding: 0px 5px;
        line-height: 40px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        box-shadow: 1px 1px 5px #1c1c1c;
        background-color: #444;
        margin: 20px 20px 0px 20px ;
        border-radius: 50px;
        z-index: 2;
        position: absolute;
    }
    #OverlapMain{
        display: flex;
        width: 100%; 
        height: 100%;
    }
    #TemplateStoreContainerNav .upload {
        font-family: '프리텐다드ExtraBold';
        color: white;
        border: none;
        border-radius: 5px;
        background: #222;
        font-size: 15px;
        height: 30px;
        padding: 0px 10px;
        margin: 10px;
        margin-right: 10px;
        line-height: 30px;
        display: flex;
        transition: background 0.15s;
        background: orange;
        border-radius: 50px;
    }
    #TemplateStoreContainerNav .category{
        color: white;
        height: 30px;
        padding: 0px 5px;
        margin: 10px;
        margin-right: 10px;
        font-size : 15px;
        display: flex;
        align-items: center;
        line-height 30px;
    }
    #TemplateStoreContainerNav .categoryList{
        display: flex;
    }
    #OverlapMain .none{
        display: flex;
        flex-direction: column;
        width: 100%; 
        height: 100%;
        align-items: center;
        justify-content: center;
    }
   
   
`;