


import * as EditorDecoder from 'service/engine/decoder/EditorDecoder'
////////////////////////////////////////////////////////
// Edittor import
////////////////////////////////////////////////////////

import * as Unknown from 'service/model/widget/UnknownModel'; // 위젯 정의 [텍스트]

import * as Text from 'service/model/widget/TextModel'; // 위젯 정의 [텍스트]
import * as TextV2 from 'service/model/widget/TextV2Model'; // 위젯 정의 [텍스트]
import * as Image from 'service/model/widget/ImageModel'; // 위젯 정의 [이미지]
import * as Notice from 'service/model/widget/NoticeModel'; // 위젯 정의 [공지]
import * as Shape from 'service/model/widget/ShapeModel'; // 위젯 정의 [도형]
import * as Video from 'service/model/widget/VideoModel'; // 위젯 정의 [비디오]
import * as Chat from 'service/model/widget/ChatModel'; // 위젯 정의 [채팅]
import * as Timer from 'service/model/widget/TimerModel'; // 위젯 정의 [타이머]
import * as Scoreboard from 'service/model/widget/ScoreboardModel'; // 위젯 정의 [스코어보드]
// import * as MatchTitle from 'service/model/widget/'; // 위젯 정의 [경기 정보]
import * as BanPick from 'service/model/widget/BanPickModel'; // 위젯 정의 [밴픽]

import * as Import from 'service/model/widget/ImportModel'; // 위젯 정의 [밴픽]
import * as PDFwidget from 'service/model/widget/PDFModel'; // 위젯 정의 [밴픽]
import * as Particles from 'service/model/widget/ParticlesModel'; // 위젯 정의 [밴픽]
import * as ScrollCaption from 'service/model/widget/ScrollCaptionModel'; // 위젯 정의 [밴픽]
import * as Background from 'service/model/widget/BackgroundModel'; // 위젯 정의 [밴픽]



////////////////////////////////////////////////////////
// 펑션
////////////////////////////////////////////////////////

export const getReference = () => {

  const WidgetReference = {
    'Unknown' : {
      'default' : 1,
      'version' : {
        1 : {component:Unknown.Component,controller:null,style:Unknown.style,animation:Unknown.animation,availableAnimation:Unknown.availableAnimation,reference:Unknown.Reference,alias:'비활성화된 위젯',editor:EditorDecoder.UnKnownV1,name:'UnknownWidget'},
      }
    },
    'Text' : {
      'default' : 2,
      'version' : {
        1 : {component:Text.Component,controller:null,style:Text.style,animation:Text.animation,availableAnimation:Text.availableAnimation,reference:Text.Reference,alias:'레거시 텍스트',editor:EditorDecoder.textV1,name:'LegacyText'},
        // 2 : {component:TextV2.Component,controller:null,style:TextV2.style,animation:TextV2.animation,availableAnimation:TextV2.availableAnimation,reference:TextV2.Reference,alias:'텍스트',editor:EditorDecoder.textV2,name:'Text'},
        2 : {component:TextV2.Component,controller:null,style:TextV2.style,animation:TextV2.animation,availableAnimation:TextV2.availableAnimation,reference:TextV2.Reference,alias:'텍스트',editor:EditorDecoder.textV2,name:'Text'},
      }
    },
    'Image' : {
      'default' : 1,
      'version' : {
        1 : {component:Image.Component,controller:null,style:Image.style,animation:Image.animation,availableAnimation:Image.availableAnimation,reference:Image.Reference,alias:'이미지',editor:EditorDecoder.ImageV1,name:'Image'},
      }
    },
    'Video' : {
      'default' : 1,
      'version' : {
        1 : {component:Video.Component,controller:Video.Controller,style:Video.style,animation:Video.animation,availableAnimation:Video.availableAnimation,reference:Video.Reference,alias:'비디오',autoPlay:Video.autoPlay,editor:EditorDecoder.VideoV1,name:'Video'},
      }
    },
    'Chat' : {
      'default' : 1,
      'version' : {
        1 : {component:Chat.Component,style:Chat.style,animation:Chat.animation,availableAnimation:Chat.availableAnimation,preset:Chat.Preset,reference:Chat.Reference,alias:'채팅',editor:EditorDecoder.ChatV1,name:'Chat'},
      }
    },
    // 'CaptureStream' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:CaptureStreamModel,reference:CaptureStreamReference,editor:null},
    //   }
    // },
    // 'Camera' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:CameraModel,reference:CameraReference,editor:null},
    //   }
    // },
    'Shape' : {
      'default' : 1,
      'version' : {
        1 : {component:Shape.Component,controller:null,style:Shape.style,animation:Shape.animation,availableAnimation:Shape.availableAnimation,reference:Shape.Reference,alias:'도형',editor:EditorDecoder.ShapeV1,name:'Shape'},
      }
    },
    // 'Line' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:LineModel,reference:LineReference,editor:null},
    //   }
    // },
    'Memo' : {
      'default' : 1,
      'version' : {
        1 : {component:Notice.Component,controller:null,style:Notice.style,animation:Notice.animation,availableAnimation:Notice.availableAnimation,preset:Notice.Preset,reference:Notice.Reference,alias:'메모',editor:EditorDecoder.NoticeV1,name:'Memo'},
      }
    },
    'Timer' : {
      'default' : 1,
      'version' : {
        1 : {component:Timer.Component,controller:null,preset:Timer.Preset,style:Timer.style,animation:Timer.animation,availableAnimation:Timer.availableAnimation,reference:Timer.Reference,alias:'타이머',autoPlay:Timer.autoPlay,editor:EditorDecoder.TimerV1,name:'Timer'},
      }
    },
    // 'Clock' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:ClockModel,reference:ClockReference,editor:null},
    //   }
    // },
    // 'Countdown' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:CountdownModel,reference:CountdownReference,editor:null},
    //   }
    // },
    'Import' : {
      'default' : 1,
      'version' : {
        1 : {component:Import.Component,controller:null,style:Import.style,animation:Import.animation,availableAnimation:Import.availableAnimation,reference:Import.Reference,alias:'가져오기',editor:EditorDecoder.ShapeV1,name:'Import'},
      }
    },
    'PDFwidget' : {
      'default' : 1,
      'version' : {
        1 : {component:PDFwidget.Component,controller:null,style:PDFwidget.style,animation:PDFwidget.animation,availableAnimation:PDFwidget.availableAnimation,reference:PDFwidget.Reference,alias:'PDF파일',editor:EditorDecoder.ShapeV1,name:'PDFfile'},
      }
    },
    'Background' : {
      'default' : 1,
      'version' : {
        1 : {component:Background.Component,controller:null,preset:Background.Preset,style:Background.style,animation:Background.animation,availableAnimation:Background.availableAnimation,reference:Background.Reference,alias:'배경',editor:EditorDecoder.BackgroundV1,name:'Background'},
      }
    },
    'Particles' : {
      'default' : 1,
      'version' : {
        1 : {component:Particles.Component,controller:null,preset:Particles.Preset,style:Particles.style,animation:Particles.animation,availableAnimation:Particles.availableAnimation,reference:Particles.Reference,alias:'파티클',editor:EditorDecoder.ShapeV1,name:'Particles'},
      }
    },
    'Scoreboard' : {
      'default' : 1,
      'version' : {
        1 : {component:Scoreboard.Component,controller:null,preset:Scoreboard.Preset,style:Scoreboard.style,animation:Scoreboard.animation,availableAnimation:Scoreboard.availableAnimation,reference:Scoreboard.Reference,alias:'스코어보드',editor:EditorDecoder.ScoreboardV1,name:'Scoreboard'},
      }
    },
    'MatchTitle' : {
      'default' : 1,
      'version' : {
        1 : {component:Scoreboard.Component,controller:null,preset:Scoreboard.Preset,style:Scoreboard.style,animation:Scoreboard.animation,availableAnimation:Scoreboard.availableAnimation,reference:Scoreboard.Reference,alias:'스코어보드',editor:EditorDecoder.ScoreboardV1,name:'Scoreboard'},
      }
    },
    'BanPick' : {
      'default' : 1,
      'version' : {
        1 : {component:BanPick.Component,controller:null,preset:BanPick.Preset,asset:BanPick.Asset,style:BanPick.style,animation:BanPick.animation,availableAnimation:BanPick.availableAnimation,reference:BanPick.Reference,alias:'밴픽',editor:EditorDecoder.BanPickV1,name:'BanPick'},
      }
    },
    // 'DraftPick' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:DraftPickModel,reference:DraftPickReference,editor:null},
    //   }
    //},
    // 'OverwatchHeroes' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:OverwatchHeroesModel,reference:OverwatchHeroesReference,editor:null},
    //   }
    // },
    // 'GameCharacters' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:GameCharactersModel,reference:GameCharactersReference,editor:null},
    //   }
    // },
    // 'GameMaps' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'OverwatchRank' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'OverwatchProfile' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'ExWidget' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'CryptoCurrency' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'ADalert' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'BroadcastAlert' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'NextSchedule' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    'ScrollCaption' : {
      'default' : 1,
      'version' : {
        1 : {component:ScrollCaption.Component,controller:null,style:ScrollCaption.style,animation:ScrollCaption.animation,availableAnimation:ScrollCaption.availableAnimation,reference:ScrollCaption.Reference,alias:'스크롤자막',editor:EditorDecoder.ScrollCaptionV1,name:'ScrollCaption'},
      }
    },
    // 'LiveIcon' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
    // 'Dday' : {
    //   'default' : 1,
    //   'version' : {
    //     1 : {style:TextModel,reference:TextReference,editor:null},
    //   }
    // },
  };



  return WidgetReference;

}


export const referenceMerge = (reference, widget) => {
  let merge = {};

  // reference의 각 키에 대해 반복
  Object.keys(reference).forEach(key => {
      // 첫 번째 레벨의 값이 객체인 경우
      if (typeof reference[key] === 'object' && reference[key] !== null && !Array.isArray(reference[key])) {
          merge[key] = {};

          // widget에 동일한 키가 없거나, 해당 키의 값이 객체가 아닌 경우 reference 값을 그대로 사용
          if (typeof widget[key] !== 'object' || widget[key] === null || Array.isArray(widget[key])) {
              merge[key] = reference[key];
          } else {
              // 두 번째 레벨의 각 프로퍼티에 대해 반복
              Object.keys(reference[key]).forEach(prop => {
                  // widget에서 값을 가져오거나, 없으면 reference에서 값을 사용
                  merge[key][prop] = widget[key]?.[prop] !== undefined ? widget[key][prop] : reference[key][prop];
              });
          }
      } else {
          // 첫 번째 레벨의 값이 객체가 아닌 경우
          merge[key] = widget[key] !== undefined ? widget[key] : reference[key];
      }
  });

  return merge;
};
