////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, {useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { Route, Link } from 'react-router-dom';
import { useHistory } from "react-router";
// 라이브러리 

// 설정 파일
import * as config from 'config/OverlapConfig'

// [컴포넌트] 위젯 
// import MiniView from "components/viewer/MiniView"
// [리덕스]스토어 연결
// import store from "store";

// SASS&CSS 연결
// import 'sass/login.scss'

// 이미지 연결
// import logoW from 'assets/logoW.svg';


// 서비스 연결
import * as Utility from 'service/other/Utility'; 
import * as DeCoder from '../../service/engine/DeCoder' ; 
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

/** [디스플레이] 
 * 프리뷰용도로 제작된 디스플레이 입니다.
 * @param {*} widgetObj 오버랩의 위젯 객체 (1개) 또는 배열 객체
 * @param {number|null} previewWidth 컴포넌트의 가로 사이즈
 * @param {number|null} previewHeight 컴포넌트의 세로 사이즈
 * @param {number} referenceWidth 디스플레이의 해상도
 * @param {number} referenceHeight 디스플레이의 해상도
 * @param {*} option 디스플레이에 적용할 옵션
 * @returns 
 */


const WidgetDisplay =  React.memo(function WidgetDisplay({ widgetObj = null, previewWidth = null, previewHeight = null, global = {}, option={} }) {
    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    // state
    // const [overlapList, setList] = useState(null);
   
    // ref

    // const displayRef = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

      // useEffect(() => {

      //   return () => {

      //   };
      // },[]);

      const width = previewWidth - 20; //  위젯창의 마진 10px
      const height = previewHeight - 20; //  위젯창의 마진 10px

      const referenceWidth = widgetObj.data.size.width;
      const referenceHeight = widgetObj.data.size.height;

      let zoom = (height / referenceHeight)??0;

    ////////////////////////////////////////////////////////
    // Fuction
    ////////////////////////////////////////////////////////
    const DisplayCore = () => {

        let targetWidget = null

        if(Array.isArray(widgetObj)){

          targetWidget = widgetObj
         
        }else {
          const WidgetCopy = Utility.deepCopy(widgetObj)
          WidgetCopy.data.position = {x:0,y:0}
          targetWidget = [WidgetCopy]
        }


        if(zoom){    
            return DeCoder.run({'page_widget':targetWidget},'view',global);
   
        }
    }

    ////////////////////////////////////////////////////////
    // JSX
    ////////////////////////////////////////////////////////


    const Ratio = referenceHeight/referenceWidth;
    
    const ContainerSizeControl ={
        height: previewHeight,
        width : previewWidth,
        alignItems: option.align??'center',
    }
    
    const displaySizeControl = {
        backgroundColor: 'white',
        aspectRatio:  `${referenceWidth} / ${referenceHeight}`,
      };

      if(previewHeight>=previewWidth*Ratio){
        displaySizeControl['width'] = previewWidth;
        zoom = (width / referenceWidth)??0;
      }else{
        displaySizeControl['height'] = previewHeight;
        zoom = (height / referenceHeight)??0;
      }


    const zoomControl = {
        transform:  `scale(${zoom})`,
        margin : 'auto'
    };
    
    return (
        <div className='WidgetDisplayContainer' style={ContainerSizeControl}  >
        {/* <style jsx>{`${DisplayStyle}${SizeStyle}`}</style> */}
        <style jsx>{`${DisplayStyle}`}</style>
            {/* <div style={displaySizeControl} className='PageDisplay' ref={ref}> */}
            <div style={displaySizeControl} className='WidgetDisplay'>
            {/* Width: {width}, Height: {height} */}
                <div  style={zoomControl} className='WidgetDisplayCore' >
                    {DisplayCore()}
                </div>
            </div>
        </div>
    );
  });

  export default WidgetDisplay;
////////////////////////////////////////////////////////
// css 부분
////////////////////////////////////////////////////////
const DisplayStyle = `
    .WidgetDisplayContainer {
        width: 100%;
        height: 100%;
        display:flex;
        justify-content: center;

    }
    .WidgetDisplayContainer .WidgetDisplay{
        background-color: white;
        margin : 10px;
        box-sizing: border-box;
        overflow: hidden;        
    }
    .WidgetDisplayContainer .WidgetDisplayCore{
        width: 100%;
        height: 100%;
        transform-origin: 0% 0%; 
    }
 
`;


