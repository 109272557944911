////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { Component, Fragment , useState, useEffect} from 'react';
import { connect } from "react-redux"; // 리덕스 연결
import { useHistory,useLocation } from 'react-router-dom';
import { SortableContainer, SortableElement,ReactSortable } from 'react-sortablejs';
// 라이브러리 



// SASS&CSS 연결
// import "sass/edit.scss"

// 이미지 연결
import chzzkLogo from 'assets/sidebar/chat/chzzk.jpg';

// 컴포넌트 연결
// import VariableTeam from 'components/edit/editVariable/varTeam/VariableTeam';//오버레이
import AppendFile from 'components/edit/editPanel/append/AppendFile';
import VarTeamPlayer from 'components/edit/editVariable/varTeam/VarTeamPlayer';

// 서비스 연결
import * as fileAPI from 'service/api/file' ; 
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility';
import { getProfile } from 'service/api/apiAccount';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function AppendProfileCreate({context,panelHeight,panelWidth,modifyWidget,listOption={}}) {


    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////
    //set

    const [FileList, setFileList] = useState(false);
    const [TragetWidget, setTragetWidget] = useState(null);

    // state
    const [SelectTeam, setSelectTeam] = useState(null);

    // rel 

   ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////
    useEffect(() => {
        // 여기에 컴포넌트가 마운트되었을 때 실행할 코드를 작성합니다.
        // getFile();
        return () => {
        // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
        };
      }, []);

      // useEffect(() => {

      //   if(!TragetWidget){
      //     setTragetWidget(context.selectWidget)
      //   }
      //   else{
      //     context.panelControl('bottom','widget')
      //   }
      //   return () => {
      //   // 컴포넌트가 언마운트될 때 실행될 함수를 반환할 수 있습니다. 이는 componentWillUnmount와 유사합니다.
      //   };
      // }, [context?.selectWidget]);


    


    const getPlatformCard = ({name,logo,summary}) => {

      return (
        <div className='platformCard'>
        <div className='platformLogo'>
        <img src={logo} width={50} height={50} style={{margin:'2px 7px 2px 2px',borderRadius:'5px'}}/> 
        </div>
        <div className='platformInfo'>
          
          <div className='platformTitle'>
            {name}
          </div>
          <div className='platformSummary'>
            {summary}
          </div>
        </div>
        
      </div>
      )

    }


    const getProfile = ({platform}) => {


      switch (platform) {
        case 'chzzk':
          return (<div>
            1
          </div>)

      
        default:
          break;
      }


    }

      
    console.log('context 상태 체크 ',context);
  

    return (
        <Fragment>
                <style jsx>{`${EditPageStyle}`}</style>
                <section id = 'AppendProfileCreate'>
                <div id="CreateProfileContainer">
                  <div id="PlatformList">
                  {getPlatformCard({
                    name : '치지직',
                    logo : chzzkLogo,
                    summary : '채팅과 프로필 정보를 가지고 옵니다.',
                  })}
                  
                  </div>
                  <div id="ProfileField">
                  
                    
                  </div>
                </div>
           
              
                </section>
        </Fragment>
    );

}

////////////////////////////////////////////////////////
// redux 부분
////////////////////////////////////////////////////////
const mapStateToProps = (state) => ({
    // mainScreen : state.mainScreen,
})

function mapDispatchToProps(dispatch){
    return {
        // initOverlap : (overlapData) => dispatch({type:'initOverlap',overlap : overlapData}),
    }
}

  // export default EditPage;
export default connect(mapStateToProps,mapDispatchToProps)(AppendProfileCreate);
  


  const EditPageStyle = `

  #AppendProfileCreate {
    display: flex;
    flex : 1 ;
    flex-direction: column;
  }
  #AppendProfileCreate #CreateProfileContainer {
    flex : 1 ;
    display: flex;

  }
  #AppendProfileCreate  #PlatformList{
    flex : 1 ;
    display: flex;
  
  }

  #AppendProfileCreate  #ProfileField{
    flex : 1 ;
    display: flex;
    background : #202020;
  }
    
  #AppendProfileCreate .platformCard{
    background : #202020;
    display: flex;
    height : 80px;
    width : 100%;
    margin : 20px;
    border-radius : 5px;
  
  }
  #AppendProfileCreate .platformLogo{
    height : 50px;
    width : 50px;
    margin : 15px;
    display:flex;
    justify-content : center;
    align-items: center;
  }

  #AppendProfileCreate .platformInfo{
    height : 50px;
    margin : 15px 15px 15px 15px;
  }

  #AppendProfileCreate .platformTitle{
    font-size : 15px;
    line-height: 20px;
    margin-bottom : 5px;
  }
  #AppendProfileCreate .platformSummary{
    font-size : 12px;
  }

  `;
  

  const modernColors = [
  "#e4002b", // PANTONE 17-1564
  "#aa8a00", // PANTONE 18-1764
  "#0ec7b5", // PANTONE 18-1550
  "#e73312", // PANTONE 18-1945
  "#5a8dff", // PANTONE 19-3540
  "#00492b", // PANTONE 19-3642
  "#FF0A07", // PANTONE 19-4052
  "#de2027", // PANTONE 18-4244
  "#0277BD", // PANTONE 18-4250
  "#FFC900", // PANTONE 18-4728
  "#ff6b01", // PANTONE 18-5624
  "#b4936a", // PANTONE 17-6153
  "#ff3e24", // PANTONE 18-0538
  "#b69265", // PANTONE 16-0737
  "#C8102E", // PANTONE 14-0957
  "#dc0e27", // PANTONE 15-1046
  "#221814", // PANTONE 16-1356
  "#23aee5", // PANTONE 18-1451
  "#b04ec4", // PANTONE 18-1449
  "#231f20", // PANTONE 19-1317
  "#d4d7dc", // PANTONE 19-0201
  "#212021", // PANTONE 19-4019
  "#231815", // PANTONE 19-4008
  "#808080", // PANTONE 18-1655
  "#0a3091", // PANTONE 19-2047
  "#216ce2", // PANTONE 19-3542
  ];