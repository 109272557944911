////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';
import YouTube from 'react-youtube';

// [프로바이더]
import EditContext from 'service/context/EditContext' ; 

// 서비스 연결
import * as Widget from 'service/edit/WidgetService' ; 
import * as Utility from 'service/other/Utility'; 
import * as Editor from 'service/edit/EditService' ; 
import { widget } from 'service/value/Model';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////
function TextV2({keyValue,pageKey,alias,type,key,id,uuid,getStyle,getAnimation,animation,parents,data}){
    
    ////////////////////////////////////////////////////////
    // Const
    ////////////////////////////////////////////////////////


    // set
    const WidgetRef = useRef(null);
    const context = useContext(EditContext);
    
    /* 모든 위젯에서만 사용되는 고유 상수 */
    const Style = getStyle(data) // Style | 스타일을 가지고 옵니다.
    const Animation = getAnimation(animation,data) // Animation | 애니메이션을 가지고 옵니다.
    const MergeStyle = Widget.styleMerge(Style,Animation); // MergeStyle | 스타일과 애니메이션을 합칩니다.


    const WidgetActivate  = Widget.activate(data); // WidgetActivate | 위젯이 숨김상태인지 아닌지 판단합니다.
    const Width = data?.size?.width // Width | 위젯의 가로 사이즈
    const Height =data?.size?.height // Height | 위젯의 세로 사이즈
    /* 해당 위젯에서만 사용되는 고유 상수 */

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////
    // Func
    ////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////
    // Widget lander
    ////////////////////////////////////////////////////////
    // console.log('MergeStyle.layout.transition',data.value.text)
    // const combinedHTML = data?.value?.text.reduce((acc, text, index) => `${acc}<span key=${index} data-key=${index}  style="color: ${text.style?.color}; font-size: ${text.style?.size??'15px'};">${text.char=='\n'?"<br/>":text.char}</span>`,'');


    const sentence = data?.value?.text?.map((text)=>(<span style={{color:text?.style?.color??'red'}}>{text.char=='\n'?<br/>:text.char}</span>))

    switch (parents) {
        /* 편집화면에서 보이는 위젯의 모습 */
        case 'edit':
            // 편집화면에서는 애니메이션 효과를 비활성화 합니다.
            MergeStyle.layout.transition = false
            MergeStyle.object.transition = false
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject my-node "+type+WidgetActivate} >
                    {/* 비디오 위젯 체크 */}
                    <div className={'TextV2'}  style={{...MergeStyle.object}} > 
                        {sentence}
                    </div>
                </div>
                </Fragment>
        
            );
        /* 방송화면에서 보이는 위젯의 모습 */
        case 'broadcast':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={MergeStyle.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                    <div className={'TextV2 '}  style={{...MergeStyle.object}} > 
                        {sentence}
                    </div>
                </div>
                </Fragment>
        
            );
        /* 미리보기 화면에서 보이는 위젯의 모습 */
        case 'view':
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={{...MergeStyle.layout}} className = {parents + " widgetObject "+type+WidgetActivate} >
                    <div className={'TextV2'}  style={{...MergeStyle.object}} > 
                    {sentence}
                    </div>
                </div>
                </Fragment>
        
            );
        default:
            return (
                <Fragment>
                <style jsx>{`${WidgetStyle}`}</style>
                <div style={Style.layout} className = {parents + " widgetObject "+type+WidgetActivate} >
                    {/* {getYoutube()} */}
                </div>
                </Fragment>
            );

    }
    

    
}

export default TextV2;

const WidgetStyle = `

 

`;
