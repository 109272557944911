////////////////////////////////////////////////////////
// import 부분
////////////////////////////////////////////////////////
// 모듈 연결
import React, { useState, useEffect, useRef, memo } from 'react';
import ContentEditable from 'react-contenteditable';
import "sass/widgetEdit.scss";
import { icon } from 'service/edit/EditService';
import * as TextEditor from 'service/edit/TextEditorService';
import * as ED from 'service/engine/decoder/EditorDecoder';

// 서비스 연결
import * as Editor from 'service/edit/EditService'; 
import * as Utility from 'service/other/Utility'; 
import { style } from 'service/model/widget/UnknownModel';
import { Tune } from '@material-ui/icons';
import { coordinateSystem } from 'service/event/widget';
////////////////////////////////////////////////////////
// component 부분
////////////////////////////////////////////////////////

const TextEditV2Form = memo(({ target,  modify, keyName, title, option }) => {

    ////////////////////////////////////////////////////////
    // State
    ////////////////////////////////////////////////////////

    // 이전 텍스트 데이터를 저장하는 Ref
    const PrevValueRef = useRef(null);
    const PrevText_JSON = useRef(null);
    // 최근에 스타일이 변경되었는지 확인하는 Ref
    const ModifyRef = useRef(false);
    // 현재 HTML 콘텐츠를 저장하는 Ref
    const HTMLRef = useRef(null);
    // 커서 위치와 선택 영역을 저장하는 Ref
    const corserRef = useRef(null);



    
    // 에디터에서 가지고 오는 위젯의 속성
    const { text } = keyName;

    // 폼의 열림/닫힘 상태를 관리하는 State
    const [Toggle, setToggle] = useState(option?.openToggle === undefined ? true : option?.openToggle);
    const [OpenForm, setOpenForm] = useState(option?.openForm === undefined ? true : option?.openForm);

    // 기본으로 정의된 텍스트 스타일을 설정하는 State
    const DefaultStyle = {
      'color': '#000000',
      'fontfamily': '프리텐다드SemiBold',
      'fontSize': 50,
      'fontWeight': 'normal'
    }
    const [DefaultTextStyle, setDefaultTextStyle] = useState(DefaultStyle);

    // 기본 스타일을 정하는 Ref
    const DefaultStyleRef = useRef(DefaultStyle);

    const [Test2, setTest2] = useState(0);

    // 커서 상태를 저장하는 State
    const [CorserState, setCorserState] = useState({ start: null, end: null, area: null });
    
    const [StyleUpdateFlag, setStyleUpdateFlag] = useState(false);
    

    // 에디터에 표시될 HTML 콘텐츠를 저장하는 State
    const [html, refresh_Html] = useState('');

    // ContentEditable 컴포넌트에 대한 Ref
    const contentEditable = useRef(null);

    ////////////////////////////////////////////////////////
    // Life Cycle
    ////////////////////////////////////////////////////////

    useEffect(() => {
      initForm();
    }, []);


    useEffect(() => {
      console.log('⭕️리렌더 HTML StyleUpdateFlag')
      restoreSelection();
    }, [html]);

    useEffect(() => {
      console.log(' CorserState 체크')
      restoreSelection();
    }, [CorserState]);



    ////////////////////////////////////////////////////////
    // function
    ////////////////////////////////////////////////////////

    /** 오버랩 데이터를 가지고 텍스트 폼을 초기화 합니다. */
    const initForm = () => {
      const WidgetText_JSON = ED.getValue(target, text);

      // 위젯의 텍스트 데이터 값을 복사해 이전 데이터 값을 담는 [PrevText_JSON] rel에 넣습니다.
      PrevText_JSON.current = Utility.deepCopy(WidgetText_JSON);
      
      PrevValueRef.current = Utility.deepCopy(WidgetText_JSON);
      // 위젯의 텍스트 데이터 값으로 HTML을 만듭니다.
      const Text_HTML = TextEditor.decoder(WidgetText_JSON);
      // 에디터가 준비 되었는지 체크합니다. 만약 준비된 경우 에디터를 포커싱 합니다.
      if (contentEditable.current) {
        contentEditable.current.focus();
      }
      // 에디터를 업데이트 합니다.
      refresh_Html(Text_HTML);
    }

    ////////////////////////////////////////////////////////
    // Handlers
    ////////////////////////////////////////////////////////

    // 새로운 글자를 입력시 실행되는 구문
    const textOnChange = (evt) => {
        // ContentEditable 의 HTML 값을 가지고 옵니다.
        HTMLRef.current = evt.target.value;
        
        // HTML을 JSON 형식으로 인코딩합니다.
        console.log('evt.type',ModifyRef.current);

        const EditableEventType = evt.type

        const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current,{styleModify:ModifyRef.current&&evt.type=='input'});


        switch (EditableEventType) {
          case 'input':
            if(ModifyRef.current){
              const LastValue = PrevValueRef.current[PrevValueRef.current.length - 1];
              let targetIndex = Value_JSON.findIndex(obj => obj.styleKey === LastValue.styleKey);

              if(Value_JSON.length - targetIndex > 0){
                ModifyRef.current= false
              }
            }
            break;
          case 'blur':

            break;
        
          default:
            break;
        }

        const Value_HTML = TextEditor.decoder(Value_JSON);
        PrevValueRef.current = Value_JSON
        HTMLRef.current = Value_HTML
        console.log('HTMLRef.current',HTMLRef.current);
        if(Value_JSON.length == 0){
          refresh_Html(Value_HTML)
        }


 

        modify(text.category, text.key, Value_JSON, target.uuid,{log:'textOnChange'});

    };

    const pushEnter = () => {
  
      const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current,{enter : corserRef.current?.start});
      // 이전 스타일 가지고 오기
      const prevText = Value_JSON[corserRef.current?.start - 1]?? Value_JSON[corserRef.current?.start];
      // 공백추가
      Value_JSON.splice(corserRef.current?.start, 0, { char: '\n', style: prevText?.style });
      corserRef.current.start = corserRef.current.start + 1;
      corserRef.current.end = corserRef.current.end + 1;
      const Value_HTML = TextEditor.decoder(Value_JSON);
      PrevValueRef.current = Value_JSON
      HTMLRef.current = Value_HTML;

      refresh_Html(Value_HTML);

      modify(text.category, text.key, Value_JSON, target.uuid,{log:'pushEnter'});
    } 

    const endEdit = () => { 
      // 문제 요인 여기서 HTML을 가지고 최신 스타일 반영불가
      const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
      const Value_HTML = TextEditor.decoder(Value_JSON);
      PrevValueRef.current = Value_JSON
      HTMLRef.current = Value_HTML;
      refresh_Html(Value_HTML);
   
      modify(text.category, text.key, Value_JSON, target.uuid,{log:'endEdit'});
      
    } 



    /**
     * HTMLRef의 값으로 HTMLState를 최신화 합니다.
     */
    const syncHtml = () => {
      // HTMLRef(최신화된 HTML) 과 html State 가 다르면 최신으로 갱신해줍니다.
      if(HTMLRef.current !== html){
        refresh_Html(HTMLRef.current);
      }
    }

    const cursorChange = (option) => {
      
    }


    const handleCursorChange = (option) => {

      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = range.cloneRange();
        preCaretRange.selectNodeContents(contentEditable.current);

        preCaretRange.setEnd(range.startContainer, range.startOffset);
        
        const getLineBreak = (range) => {
          const div = document.createElement('div');
          div.appendChild(range.cloneContents());
          return (div.innerHTML.match(/<br\s*\/?>/gi) || []).length;
        };

        const startLineBreak = getLineBreak(preCaretRange);
        const start = preCaretRange.toString().length + startLineBreak;
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        const endLineBreak = getLineBreak(preCaretRange);
        const end = preCaretRange.toString().length + endLineBreak;

        const isCollapsed = range.collapsed
        corserRef.current = { start, end ,area: !isCollapsed};
        if(option?.refresh) {
          contentEditable.current.blur();



        // 범위가 아닌 경우
        if(isCollapsed&&option?.use != 'keyboard'){
        //   const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
        //   // Value_JSON[start-1]

        //   const voidControl = {
        //     "char": "​",
        //     "styleKey": 'temp',
        //     "style": Utility.deepCopy(DefaultStyleRef.current)
        // }
      

        //   Value_JSON.splice(start, 0, voidControl);
        //   console.log('클릭한 제이슨 :',Value_JSON)
        //   const Value_HTML = TextEditor.decoder(Value_JSON);
        //   corserRef.current['start'] = corserRef.current.start + 1
        //   corserRef.current['end'] = corserRef.current.end + 1
        //   setCorserState(corserRef.current);
        //   refresh_Html(Value_HTML);
        //   setStyleUpdateFlag(false)
        setCorserState(corserRef.current);
        refresh_Html(HTMLRef.current);
        }else{
          // 사용자가 방금 수정하지 않았다면
          if(end&&ModifyRef.current==false){
            const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
            DefaultStyleRef.current = Value_JSON[end-1]?.style??{'color':'#000000','fontfamily':'프리텐다드SemiBold','fontSize':50,'fontWeight':'normal'}

          }
          setCorserState(corserRef.current);
          refresh_Html(HTMLRef.current);
        }
     
        // const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
        // const Value_HTML = TextEditor.decoder(Value_JSON);
  

        }
      }
    };

    
    const restoreSelection = () => {
      // contentEditable 요소와 커서 참조가 존재하는지 확인합니다.
      if (contentEditable.current && corserRef.current?.start != null && corserRef.current?.end != null) {
        try {
          // 새 Range 객체를 생성합니다.
          const range = document.createRange();
    
          // Range의 시작 위치와 끝 위치를 설정합니다.
          range.setStart(contentEditable.current.childNodes[corserRef.current.start], 0);
          range.setEnd(contentEditable.current.childNodes[corserRef.current.end], 0);
    
          // 현재 선택된 범위를 초기화합니다.
          const selection = window.getSelection();
          selection.removeAllRanges();
    
          // 새로 만든 Range를 선택합니다.
          selection.addRange(range);
    
          // contentEditable 요소를 블러 처리한 후 포커스를 다시 맞춥니다.
          contentEditable.current.blur();
          contentEditable.current.focus();
          
        } catch (error) {
          // 에러가 발생하면 콘솔에 에러를 출력합니다.
          console.error(error);
        }
      }
    };

    // const setCursorPosition = (position) => {
    //   if (contentEditable.current) {
    //     const element = contentEditable.current;
    //     const range = document.createRange();
    //     const selection = window.getSelection();
    
    //     // 텍스트 노드와 요소 노드를 처리하기 위해 재귀적으로 노드를 순회합니다.
    //     const traverseNodes = (node, charsLeft) => {
    //       if (charsLeft === 0) {
    //         range.setStart(node, 0);
    //         range.collapse(true);
    //         return;
    //       }
    
    //       if (node.nodeType === Node.TEXT_NODE) {
    //         if (node.textContent.length >= charsLeft) {
    //           range.setStart(node, charsLeft);
    //           range.collapse(true);
    //           return;
    //         } else {
    //           charsLeft -= node.textContent.length;
    //         }
    //       } else {
    //         for (let i = 0; i < node.childNodes.length; i++) {
    //           const child = node.childNodes[i];
    //           traverseNodes(child, charsLeft);
    //           if (range.startContainer) {
    //             return;
    //           }
    //         }
    //       }
    //     };
    
    //     traverseNodes(element, position);
    
    //     selection.removeAllRanges();
    //     selection.addRange(range);
    
    //     // contentEditable 요소에 포커스를 맞춥니다.
    //     element.focus();
    //   }
    // };

    const handleKeyDown = (e) => {

      switch (e.key) {
        case 'Enter':
          if(!e.nativeEvent.isComposing){
            e.preventDefault();
            pushEnter();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
        case 'ArrowRight':
        case 'ArrowDown':

          // pushEnter();
          break;      
        default:
          break;
      }

    };

    const handleKeyUp = (e) => {
      switch (e.key) {
        case 'Enter':
          if(!e.nativeEvent.isComposing){
  
          }else{
            handleCursorChange();
          }
          break;
        case 'ArrowLeft':
        case 'ArrowUp':
          handleCursorChange({refresh:true,dragDirection:'left',use:'keyboard'});
          break;      
        case 'ArrowRight':
        case 'ArrowDown':
          handleCursorChange({refresh:true,dragDirection:'right',use:'keyboard'});
          break;      
        default:
          handleCursorChange();
          break;
      }
  
      // console.log('StyleUpdateFlag',StyleUpdateFlag);
      // if(StyleUpdateFlag){

      //   handleCursorChange()
      //   refresh_Html(HTMLRef.current)
      //   setStyleUpdateFlag(false)

      // }

      if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
        console.log('검사 패스', corserRef.current);
      } else {
  
      }
    };


    


    /**
     * 선택된 영역의 스타일을 변경합니다.
     * @param {*} ValueJSON 
     * @param {*} widgetKey 변경할 스타일의 종류
     * @param {*} widgetValue 변경할 스타일의 값
     * @param {*} start 선택된 영역의 시작점
     * @param {*} end 선택된 영역의 종료점
     */
    const modifyArea = (ValueJSON,widgetKey,widgetValue,start,end) => {

      // 깊은 복사를 통해서 새로운 배열을 만들어줍니다. *이상하게 DEEPCOPY 안먹음
      ValueJSON =  JSON.parse(JSON.stringify(ValueJSON));

      // 텍스트 필드를 순회합니다.
      for (let index = start; index < end; index++) {
        // 문자의 정의된 스타일을 불러옵니다. 없는경우 빈 배열을 가지고 옵니다.
        const CharStyle = ValueJSON[index]?.style??{}
        // 새로운 스타일을 문자에 적용시킵니다.
        CharStyle[widgetKey] = widgetValue;
        ValueJSON[index]['style'] = CharStyle;
      }
      
      // 새로운 스타일을 문자에 적용시킵니다.
      const ValueHTML = TextEditor.decoder(ValueJSON);

      // 이전 JSON 값에 JSON 을 적용 해줍니다.
      PrevValueRef.current = ValueJSON;

      // HTML 값에 HTML 을 적용 해줍니다.
      HTMLRef.current = ValueHTML;

      // 변경된 텍스트를 서버로 전송합니다.
      modify(text.category, text.key, ValueJSON, target.uuid,{log:'CharStyleModify - 영역 스타일 변경'});

      // HTML 을 다시 랜더합니다.
      refresh_Html(ValueHTML);
    }




    // const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
    // const Value_HTML = TextEditor.decoder(Value_JSON);
    // // if (DefaultStyleRef.current) {
    // //   DefaultStyleRef.current[key] = value;
    // // }
    // modify(text.category, text.key, Value_JSON, target.uuid,{log:'CharStyleModify - 기본 스타일 변경'});
    // refresh_Html(Value_HTML);
    // const NextDefaultTextStyle = Utility.deepCopy(DefaultStyleRef.current);
    // ModifyRef.current = true;
    // // setDefaultTextStyle(NextDefaultTextStyle) 
    // console.log('데스크 체크',corserRef.current)
    // setStyleUpdateFlag(true)

    const modifyCursor = (Value_JSON) => {
        setTest2(Test2 +1)

          setStyleUpdateFlag(true)
      // modify(text.category, text.key, Value_JSON, target.uuid,{log:'CharStyleModify - 기본 스타일 변경'});
    }
    


  
    const modifyStyle = (key,value) => {

      // 텍스트 필드 내 커서의 위치를 고정합니다.
      restoreSelection(); 

      // 기본 스타일에 변경된 스타일을 반영합니다. (스타일 로딩 확인)
      if (DefaultStyleRef.current) {
        DefaultStyleRef.current[key] =  Utility.deepCopy(value);
      }

      // 텍스트 필드의 상태를 가지고 옵니다.
      const ValueJSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);

      // 영역을 찾습니다 *커서의 두개의 좌표중 작은수가 Start 큰 수가 End 에 담깁니다.
      const CorserStart = corserRef.current.start > corserRef.current.end ? corserRef.current.end : corserRef.current.start;
      const CorserEnd = corserRef.current.start > corserRef.current.end ? corserRef.current.start : corserRef.current.end;
      
      // 선택영역안의 텍스트를 변경 하는 경우 (커서로 블록 지정이 되어 있는 경우)
      if(corserRef.current?.area == true){
        modifyArea(ValueJSON,key,value,CorserStart,CorserEnd)
      }
      // 새로운 택스트에 적용할 스타일을 지정하는 경우
      else if(corserRef.current?.area == false && CorserStart == CorserEnd){
        modifyCursor(ValueJSON,CorserStart)
      }

    }


    // /**
    //  * CharStyleModify2
    //  */
    // const CharStyleModify2 = (key,value) => {

    //   restoreSelection();

    //   // 선택 영역이 범위인 경우
    //   if(corserRef.current?.area == true){

    //     const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
    //     const Start = corserRef.current.start > corserRef.current.end ? corserRef.current.end : corserRef.current.start;
    //     const End = corserRef.current.start > corserRef.current.end ? corserRef.current.start : corserRef.current.end;
  
    //     console.log( '체어 이전',Value_JSON )
    //     for (let index = 0; index < Value_JSON.length; index++) {
    //       if (index >= Start && index < End){

    //         const char =  Utility.deepCopy(Value_JSON[index]);
    //         console.log('체어',char)
    //         if (char.style) {
    //           char.style.color = value ?? '#000';
    //         } else {
    //           char.style = { color: value ?? '#000' };
    //         }
    //         Value_JSON[index]= char;
    //       }
    //     }
    //     console.log( '체어 이후',Value_JSON )
        
    //     const Value_HTML = TextEditor.decoder( Utility.deepCopy(Value_JSON) );
    //     PrevValueRef.current = Value_JSON
    //     HTMLRef.current = Value_HTML;
    //     // HTMLRef.current = Value_HTML +'<span></span>';
 
    //     if (DefaultStyleRef.current) {
    //       DefaultStyleRef.current[key] =  Utility.deepCopy(value);
    //     }

    //     modify(text.category, text.key, Value_JSON, target.uuid,{log:'CharStyleModify - 영역 스타일 변경'});
    //     refresh_Html(Value_HTML);
    //     const NextDefaultTextStyle = Utility.deepCopy(DefaultStyleRef.current);


    //     // setDefaultTextStyle(NextDefaultTextStyle) 

    //   } else if (corserRef.current?.area == false){
    //     const Value_JSON = TextEditor.encoder(HTMLRef.current,PrevValueRef.current,DefaultStyleRef.current);
    //     const Value_HTML = TextEditor.decoder(Value_JSON);
    //     if (DefaultStyleRef.current) {
    //       DefaultStyleRef.current[key] = value;
    //     }
    //     modify(text.category, text.key, Value_JSON, target.uuid,{log:'CharStyleModify - 기본 스타일 변경'});
    //     refresh_Html(Value_HTML);
    //     const NextDefaultTextStyle = Utility.deepCopy(DefaultStyleRef.current);
    //     ModifyRef.current = true;

    //     setStyleUpdateFlag(true)

    //   }      
    // }


    const TextHandleNav = (targetStyle) => {
      return (
        <nav className='TextEditNav'>
        {/* 폰트 만들기 */}
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
            {Editor.icon({icon:'format_bold',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_italic',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptpnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_underlined',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptpnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'arrow_drop_up',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptpnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'arrow_drop_down',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton OptipnButton'>
          <div className='SingleButton'> 
          {Editor.icon({icon:'format_bold',size:20,lineHeight:25})}
          </div>
        </button>
        <button className='TextEditNavButton SizeFrom'>
          <input className='SelectColorCode' type='number' style={{color:'ValidColor'?"#FFFFFF":"#FF0000"}}  maxLength="7"  value={DefaultStyleRef.current?.fontSize??50} onChange={(e) => modifyStyle('fontSize',Number(e.target.value))} />
        </button>     

        <button className='TextEditNavButton ColorForm'>
          <input type="color" value={DefaultStyleRef.current?.color??'#000000'} onChange={(e) => modifyStyle('color',e.target.value.toUpperCase())} />
        </button>
        </nav>
      )
      
    }

    const TargetStart = CorserState?.start??null
    const TargetEnd = CorserState?.end??null
    const TargetArea = CorserState?.area??false

    const TargetChar = TargetStart? PrevValueRef?.current?.[TargetStart]?.char : null;
    const TargetStyle = TargetStart? PrevValueRef?.current?.[TargetStart]?.style : null;
    return (
      <section className='TextEditV2Form'>
        <style jsx>{`${FormStyle}`}</style>
        <div className={OpenForm ? 'editContainer containerOpen' : 'editContainer containerClose'}>
          <article className="EditPart TextEditor">
            {TextHandleNav(TargetStyle)}
            <ContentEditable
              className='ContentEditable'
              innerRef={contentEditable}
              html={html + `<BR data-key="null"/>`}
              disabled={false}
              onChange={textOnChange}
              onMouseLeave={()=>syncHtml()}
              onKeyUp={handleKeyUp}
              onKeyDown={handleKeyDown}
              onMouseUp={()=>handleCursorChange({refresh:true})}
              tagName="div"
              style={{padding: '10px', minHeight: '100px' ,...DefaultStyleRef.current,fontSize:15}}
            />
          </article>
        </div>
        <div style={{display:'none'}}>
          {Test2}
        </div>
        {/* <div>
          컨트롤 박스
          {!TargetArea?
            <p>단어 : {TargetChar} 	&#91;{TargetStart}&#93;   </p>
            :
            <p>단어 : {TargetChar} 	&#91;{TargetStart}&#93; ~ {TargetChar} 	&#91;{TargetEnd}&#93;   </p>
          }          {Test2}

          <p>KEY : {JSON.stringify(TargetStyle)} </p>
          <p>KEY : {JSON.stringify(DefaultStyleRef.current)} </p>

          
        </div>
        <div>
          <p>선택 상태:{corserRef?.current?.area?'네':'아니요'}</p>
          <p>Start:  {CorserState.start}</p>
          <p>End: {corserRef?.current?.end??'null'}</p>
        </div>
          <textarea>{html}</textarea>
        <div dangerouslySetInnerHTML={{ __html: html }} /> */}
      </section>
    );

}, () => true);

export default TextEditV2Form;

const FormStyle = `
.TextEditV2Form #EditControl {
  width : 100%;
  height: 100%;
  position: absolute;
  z-index : 2;
  outline: none;
}

.TextEditV2Form .ContentEditable {
  /** background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, #ccc 25%, #ccc 75%, #fff 75%, #fff); 
  background-size: 8px 8px;
  background-position: 0 0, 4px 4px; */ 
  background : #fff;
  border-radius : 5px;
  outline: none;
  border: none;
  margin : 10px 0px;
}

.TextEditV2Form .TextEditNav{
  margin : 10px 0px;
  display: flex;
  flex-wrap: wrap;
  background : #222;
  border-radius : 5px;
}
.TextEditV2Form .TextEditNavButton{
    height: 25px;
    margin: 5px;
    background-color: #3F3F3F;
    border-radius: 2.5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TextEditV2Form .TextEditNavButton.OptipnButton:active{
    transform: scale(0.8);
  }

.TextEditV2Form .SizeFrom{
    height: 25px;
    width : 50px;
}
.TextEditV2Form .SizeFrom input{
    height: 25px;
    width : 50px;
    font-family: "프리텐다드SemiBold", sans-serif;

        text-align: center;
}


.TextEditV2Form .SingleButton{
    height: 25px;
    width : 25px;
}

.TextEditV2Form .TextEditNavButton input{
    background-color: transparent;
    border: none; 
    height: 25px;
    font-family: "프리텐다드SemiBold", sans-serif;

}

.TextEditV2Form .ColorFrom{
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TextEditV2Form .ColorForm .SelectColorCode{
  height: 20px;
  width: 70px;
  padding : 0px;
  margin : 0px; 
  font-family: "프리텐다드SemiBold", sans-serif;
  font-size : 14px;
  line-height : 20px;
  text-align : center;    
  outline: none;
}




.TextEditV2Form .ColorForm input[type="color"] {
  -webkit-appearance: none;
  border: none;
  width: 20px;
  height: 20px;
  margin : 2.5px;
  border-radius: 2px;
  cursor: pointer;
  padding: 0;
}

/* Remove default internal padding and appearance for webkit browsers */
.TextEditV2Form .ColorForm input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
  border-radius: 5px;
}
.TextEditV2Form .ColorForm input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 5px;
}

`;

const Counter = ({val}) => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      {val}
    </div>
  );
};
